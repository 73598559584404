import { HttpConfig } from '@zera-admin/classes'
import { storage } from '@zera-admin/helpers'

import authService from 'services/http/identity-server/auth'

export const refresh = (config: HttpConfig): Promise<HttpConfig | void> => {
  const refreshToken = storage.get('refreshToken')

  const onLogout = () => {
    storage.remove('token')
    storage.remove('refreshToken')
    window.location.href = '/'
  }

  if (!refreshToken || config.url.indexOf('refresh-token') !== -1) {
    onLogout()

    return Promise.resolve(config)
  }

  return authService
    .refreshToken({
      refreshToken: storage.get('refreshToken'),
    })
    .then((response) => {
      const { accessToken, refreshToken } = response.data

      if (accessToken) {
        storage.set('token', accessToken)
        storage.set('refreshToken', refreshToken)

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        }

        return config
      }
    })
    .catch(() => onLogout())
}
