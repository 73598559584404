import { request } from '../instance'

import {
  mapAuthForLoginRequest,
  mapAuthForRefreshTokenRequest,
  mapAuthForTwoFactorRequest,
  mapAuthResponse,
} from './mappers'
import {
  AuthRefreshTokenRequest,
  AuthTwoFactorRequest,
  AuthLoginRequest,
  AuthResponse,
} from './types'

export const login = (data: AuthLoginRequest) =>
  request<AuthResponse>({
    method: 'post',
    url: 'auth/login',
    data,
    mappers: {
      req: mapAuthForLoginRequest,
      res: mapAuthResponse,
    },
  })

export const twoFactor = (params: AuthTwoFactorRequest) =>
  request<AuthResponse>({
    method: 'get',
    params,
    url: 'auth/two-factor-authorize',
    mappers: {
      req: mapAuthForTwoFactorRequest,
      res: mapAuthResponse,
    },
  })

export const refreshToken = (params: AuthRefreshTokenRequest) =>
  request<AuthResponse>({
    method: 'get',
    params,
    url: 'auth/refresh-token',
    mappers: {
      req: mapAuthForRefreshTokenRequest,
      res: mapAuthResponse,
    },
  })

export const validation = () =>
  request<AuthResponse>({
    method: 'get',
    url: 'auth/validation',
  })
